<template>
    <section class="wrapper" id="template_page_wrapper">
        <section class="toolbar noprint">
            <div class="report--id">Case ID : {{ getCaseId }}</div>
            <div class="template--type" v-if="!autoSavingDocument">
                {{ selectedTemplate.name }}
            </div>
            <div class="loader" v-if="autoSavingDocument">
                Loading...
            </div>
            <!-- <neo-button
        label="Case Report"
        bg="var(--brand-color)"
        hoverBg="var(--brand-color)"
        color="white"
        padding=" 0.5rem 1.25rem"
        style="margin-right: 20px"
        hoverText="white"
        v-if="caseReport && !showFinalTemplateEditor"
        @click="saveCaseReport(REPORT_SAVE_TYPES.FINAL_REPORT)"
      ></neo-button> -->

            <neo-button label="Save Report" bg="var(--brand-color)" hoverBg="var(--brand-color)" color="white" padding=" 0.5rem 1.25rem" style="margin-right: 20px" hoverText="white" v-if="showFinalTemplateEditor" @click="$modal.show('reportName')"></neo-button>
        </section>

        <!-- <section class="main--wrapper" v-if="showFinalTemplateEditor">
      <div
        class="document--editor--expand"
        v-if="expandHistorySidebar == false"
      >
        <img
          src="../../assets/icons/ico-restore.svg"
          width="20"
          height="20"
          alt="ico"
          @click="expandHistorySidebar = true"
        />
      </div>
      <div
        class="saved--history--sidebar noprint"
        v-if="expandHistorySidebar == true"
      >
        <div class="loader" v-if="!savedDocuments.docs"></div>
        <span @click="expandHistorySidebar = false">
          <img
            src="../../assets/icons/ico-caret-right-blue.svg"
            width="20"
            height="20"
            alt="ico"
            class="document--editor--collapse"
        /></span>
        <ul class="saved--docs--link">
          <a
            ><li v-for="(link, index) in savedDocuments.docs" :key="index">
              {{ link.timestamp }}
              <img
                @click="selectedTemplate.html = link.case_report"
                src="../../assets/icons/ico-restore.svg"
                width="20"
                height="20"
                :alt="ico"
                style="float: right; cursor: pointer"
              /></li
          ></a>
        </ul>
      </div>
      <div class="document--area" v-on:click="handleClick">
        <div
          v-html="getSelectedTemplateHtml + printMedia + fixFooterCss"
          class="template_page"
        ></div>
      </div>
    </section> -->
        <section class="template_section noprint" v-if="!showFinalTemplateEditor">
            <div class="container">
                <neo-tab :tabs="tabs" :active="currentTab" @tabChange="currentTab = $event" style="background-color: transparent !important"></neo-tab>
                <div class="template_wrapper">
                    <div class="report" v-on:click="selectTemplate(-1)" style="position: absolute">
                        <div v-bind:style="{backgroundImage: 'url(' + addIcon + ')'}" class="img">
                            <h3>Add New</h3>
                        </div>
                    </div>
                    <div class="reports_templates">
                        <div class="report" v-for="item in currentTab === 'Templates' ? templates : finalReports" :key="item.id" v-on:click="selectTemplate(item.id ? item.id : item._id)">
                            <div
                                v-bind:style="{
                                    backgroundImage: 'url(' + getImage(item.thumb) + ')',
                                }"
                                class="img"
                            >
                                <h3 style="overflow-wrap: break-word; word-wrap: break-word; hyphens: auto">
                                    {{ item.name }}
                                </h3>
                            </div>
                        </div>
                        <div class="report" id="loading-template" v-if="templates.length < 1"></div>
                    </div>
                </div>
            </div>
        </section>
        <section>
            <div class="container" id="mainContainer"></div>
        </section>

        <div id="colorContextMenu">
            <!-- <sketch-picker :value="headerFooterBgs['header']" @input="updateHeaderFooterColor($event)" style="margin-left:-4px;" /> -->
        </div>
    </section>
    <!-- <div class="container">
    <div class="row">
      <div class="col-md-12" style="margin-top:100px">
       Templates
      </div>
    </div>
  </div> -->
</template>

<script>
import {baseBlackboxUrl} from "../../main";
import Button from "@/components/button";
import {REPORT_SAVE_TYPES} from "@/utils/constants";
import axios from "@/axios";
import Editor from "./editor.js";
import {Sketch} from "vue-color";
import {mapActions, mapGetters} from "vuex";
import Tab from "@/components/tab";
import CustomInput from "@/components/input";

export default {
    name: "Template",
    components: {
        "neo-button": Button,
        "sketch-picker": Sketch,
        "neo-tab": Tab,
        "neo-input": CustomInput,
    },
    data() {
        return {
            fixFooterCss: `
        <style>
          .footer{
            opacity:1;
          }

        </style>
      `,
            caseReport: "",
            expandHistorySidebar: false,
            showFinalTemplateEditor: false,
            autoSavingDocument: false,
            savedDocuments: [],
            settings: false,
            settingType: "none",
            REPORT_SAVE_TYPES,
            dummyIcon: require("../../assets/pdf.svg"),
            addIcon: require("../../assets/add.svg"),
            selectedTemplate: {name: ""},
            BASE_URL: baseBlackboxUrl,
            STAGING_URL: baseBlackboxUrl,
            sections: [],
            templates: [],
            loader: null,
            selectedImg: "",
            color: {
                bg: "#000000",
                text: "#000000",
            },
            printMedia: `
        `,
            bgColor: "#343a40",
            tableProperties: {
                width: 100,
                border: {
                    width: 1,
                    color: "#dee2e6",
                    type: "solid",
                },
            },
            imgPath: "",
            tabs: [
                {key: "Templates", name: "Templates", enabled: true},
                {key: "Reports", name: "Reports", enabled: true},
            ],
            currentTab: "Templates",
            finalReports: [],
            reportName: "",
            errorReportName: false,
        };
    },
    computed: {
        getCaseId: function() {
            return this.$store.getters.getCaseId;
        },
        getSelectedTemplateHtml() {
            return this.selectedTemplate.html;
        },
        ...mapGetters(["reportTemplates"]),
    },

    methods: {
        ...mapActions(["fetchAllReportTemplates"]),
        handleBeforeOpen() {
            this.reportName = "";
            this.errorReportName = false;
        },
        fetchReportOfCase() {
            let url = `/reports/case-reports/${this.getCaseId ? this.getCaseId : "ORCA-b819c2a7ec99"}?report_type=${REPORT_SAVE_TYPES.FINAL_REPORT}`;
            let response = axios.get(url).then((response) => {
                this.caseReport = response.data.docs[0].case_report;
                this.finalReports = response.data.docs.map((n) => ({
                    id: n.id,
                    html: n.case_report,
                    name: n.name,
                }));
            });
        },

        getImage: function(url) {
            return url ? url : this.dummyIcon;
        },

        selectTemplate: function(id) {
            if (id == -1) {
                this.$router.push("/create-report");
                return;
            }

            // setTimeout(function () {
            //   that.selectedTemplate =
            //     that.currentTab === "Templates"
            //       ? that.templates[id]
            //       : that.finalReports[id];
            // }, 200);
            this.currentTab == "Templates" ? this.$router.push(`/template/${id}#templates`) : this.$router.push(`/template/${id}#reports`);
            // let that = this;
            // setInterval(function () {
            //   that.saveCaseReport(REPORT_SAVE_TYPES.AUTO_SAVED_REPORT);
            //   that.fetchAutoSavedDocs();
            // }, 10000);

            // document
            //   .getElementsByClassName("template_section")[0]
            //   .classList.add("make_collapse");
            this.showFinalTemplateEditor = true;
        },
        // getTemplates: function () {
        //   // this.savedTemplates = localStorage.getItem("templates") ? JSON.parse(localStorage.getItem("templates")) : [];
        //   let url = this.STAGING_URL + "/api/v1/template/all";
        //   fetch(url, {
        //     method: "GET",
        //     headers: {
        //       Authorization: "Basic bmVvdXNlcjpub3RhZGVtb0AxMjM=",
        //     },
        //   })
        //     .then((response) => response.json())
        //     .then((data) => {
        //       let allTemplates = [];
        //       data.data.forEach((temp) => {
        //         let reportSections = temp.body.map((val) => {
        //           return this.sections.find((sec) => {
        //             return sec.id == val;
        //           });
        //         });
        //         let templatePage = {
        //           id: temp.id,
        //           name: temp.name ? temp.name : "Untitled Document",
        //           html: "",
        //         };
        //         reportSections.forEach((repo) => {
        //           if (repo) templatePage.html = templatePage.html + repo.html;
        //         });
        //         allTemplates.push(templatePage);
        //       });
        //       this.templates = [...this.templates, ...allTemplates];
        //       this.loader.hide();
        //       this.activeHeaders();
        //     })
        //     .catch((error) => {
        //     });
        // },
        activeHeaders: function() {
            let headers = document.getElementsByClassName("editor-header");
        },
        // getAllSections: function () {
        //   let url = this.BASE_URL + "/api/v1/reports";
        //   fetch(url, {
        //     method: "GET",
        //     headers: {
        //       Authorization: "Basic bmVvdXNlcjpub3RhZGVtb0AxMjM=",
        //     },
        //   })
        //     .then((response) => response.json())
        //     .then((data) => {
        //       this.sections = data;
        //       this.getTemplates();
        //     })
        //     .catch((error) => {
        //     });
        // },

        handleClick: function(e) {
            this.editor = new Editor("editor", {
                dragger: false,
                ui: false,
            });
            let that = this;
            let selection = window.getSelection ? window.getSelection() : document.selection.createRange().text;
            if (event.target.tagName == "IMG") {
                that.settings = true;
                that.selectedImg = event.target;
                that.settingType = "image";
            } else if (selection.type != "None") {
                that.settings = true;
                that.settingType = "text";
                // if(selection.baseNode!=null){
                //   let selectedElem = selection.baseNode.parentNode;
                // }
            }
        },
        justifyContent: function(type) {
            // this.toggleCmd(type, {style : [{key : "text-align", value : type}, {key : "display" , value : "block"}]})
            document.execCommand(type);
        },
    },

    async mounted() {
        let that = this;
        this.loader = this.$loading.show({
            container: document.getElementById("loading-template"),
            canCancel: false,
            color: "#0d69d5",
            loader: "bars",
            blur: "20px",
            opacity: 0.7,
        });
        // await this.fetchAllReportTemplates();
        let {data} = await axios.get("/template/all");
        this.templates = data?.data;
        this.loader.hide();
        this.activeHeaders();

        try {
            this.fetchReportOfCase();
        } catch (err) {}

        // document.body.addEventListener("click", function (event){
        //   console.dir(event.target)
        //   if(!event.target.closest("document--area")) return;
        //   let selection = window.getSelection ? window.getSelection() : document.selection.createRange().text;
        //   if(event.target.tagName=="IMG"){
        //     that.settings = true
        //     that.selectedImg = event.target;
        //     that.settingType = "image";
        //   }
        //   else if(selection.type!="None"){
        //     that.settings = true
        //     that.settingType = "text";
        //     // if(selection.baseNode!=null){
        //     //   let selectedElem = selection.baseNode.parentNode;
        //     // }
        //   }
        // })

        // this.getTemplates();
    },
};
</script>

<style lang="scss" scoped>
::v-deep {
    @import "~bootstrap/scss/bootstrap.scss";
    @import "~bootstrap-vue/src/index.scss";
}
* {
    transition: 0.2s all !important;
}
.container {
    position: relative;
}
.wrapper {
    // min-height: 100vh;
    background-color: cyan;
}
.selection_temp {
    display: none;
}
.case_input {
    margin-bottom: 20px;
    border: 0px;
    width: 60%;
    height: 45px;
    border-radius: 5px;
    padding: 10px 20px;
    outline: none;
    left: 0;
    top: 0;
    font-weight: 900;
    font-size: 20px;
    color: #044052;
}
.template_section {
    background-color: #f1f3f4;
    padding: 30px;
    display: flex;
    flex-direction: row-reverse;
    min-height: 100vh;
    /* height : 400px */
}
.template_wrapper {
    display: block;
    /* height: 200px; */
}
.reports_templates {
    overflow: auto;
    margin-left: 158px;
}
.reports_templates .report {
    float: left;
    display: inline-block;
}
.report .img {
    margin-bottom: 10px;
    height: 183px;
    border: 1px solid #dadce0;
    border-radius: 4px;
    cursor: pointer;
    width: 100%;
    background-size: 32%;
    background-repeat: no-repeat;
    background-color: #fff;
    background-position: center 55px;
    display: flex;
    flex-direction: column-reverse;
}
.report .img:hover {
    border: 1px solid rgb(21, 155, 216);
}
.report h3 {
    font-size: 12px;
    font-weight: bold;
    padding-left: 3px;
    text-align: center;
    background: #f0f3f4;
    margin: 0;
    padding: 10px 0px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
}
.report {
    width: 144px;
    margin-right: 15px;
}
.make_collapse .template_wrapper {
    display: none;
}
.make_collapse {
    height: 80px;
}
.make_collapse .case_input {
    position: absolute;
    top: -13px;
    left: 185px;
}
.make_collapse .selection_temp {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
}
.template_page {
    box-shadow: rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
    margin-top: 20px;
    margin-bottom: 20px;
    background: #fff;
    // width: 60%;
    width: 794px;
    margin: 0 auto;
    padding-top: 3px;
    margin-top: 74px;
}
#loading-template {
    height: 183px;
    width: calc(100% - 165px);
    position: relative;
    margin-right: 0;
}

.loader,
.loader:before,
.loader:after {
    border-radius: 50%;
    width: 2.5em;
    height: 2.5em;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation: load7 1.8s infinite ease-in-out;
    animation: load7 1.8s infinite ease-in-out;
}
.loader {
    position: absolute;
    margin-left: 50%;
    color: #216ad4;
    font-size: 5px;
    text-indent: -9999em;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
}
.loader:before,
.loader:after {
    content: "";
    position: absolute;
    top: 0;
}
.loader:before {
    left: -3.5em;
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
}
.loader:after {
    left: 3.5em;
}
@-webkit-keyframes load7 {
    0%,
    80%,
    100% {
        box-shadow: 0 2.5em 0 -1.3em;
    }
    40% {
        box-shadow: 0 2.5em 0 0;
    }
}
@keyframes load7 {
    0%,
    80%,
    100% {
        box-shadow: 0 2.5em 0 -1.3em;
    }
    40% {
        box-shadow: 0 2.5em 0 0;
    }
}

.toolbar {
    // background:cyan;
    background: whitesmoke;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .report--id {
        margin-left: 20px;
        font-size: x-large;
        font-weight: initial;
        color: #216ad4;
    }
}

.document--version {
    background: cyan;
}

.main--wrapper {
    // background:cyan;
    height: 100vh;
    display: flex;

    background: #383838;
    // background:#ff1313;

    .document--editor--expand {
        background: whitesmoke;
        padding: 10px 10px;
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
        height: 46px;
        cursor: pointer;
        margin-top: 20px;
    }
    .saved--history--sidebar {
        margin-top: 0px;

        flex: 2;
        background: white;
        padding-top: 60px;
        position: relative;

        position: fixed;
        height: 100vh;
        width: 400px;

        .saved--docs--link {
            list-style-type: none;
            color: dodgerblue;
            width: 80%;

            li {
                margin-top: 10px;
            }
        }

        .document--editor--collapse {
            position: absolute;
            top: 10px;
            right: 10px;

            cursor: pointer;
            -webkit-transform: scaleX(-1);
        }
    }
    .document--area {
        // background:pink;
        // background:#525659;
        // background:#33a7ff;
        background: #383838;
        overflow-y: scroll;
        flex: 10;
    }
}

#contextMenuContainer {
    display: none;
    position: fixed;
    background: #3e3d3d;
    z-index: 100000;
}
#colorContextMenu {
    display: none;
    position: fixed;
    background: var(--brand-accent-dark);
    z-index: 100000;
}
#contextMenuContainer ul {
    padding-left: 0;
    width: 150px;
    margin: 8px 0px;
}
#contextMenuContainer ul li {
    list-style-type: none;
    padding: 2px 10px;
    color: #fff;
    font-size: 12px;
    cursor: pointer;
    position: relative;
}
#contextMenuContainer ul li ul {
    position: absolute;
    display: none;
    top: 13px;
    left: 50px;
    background: #82797c;
    z-index: 100;
}
#contextMenuContainer ul li:hover ul {
    display: block;
}
#contextMenuContainer ul li:hover {
    background: #5a5959;
}

#editor--options--sidepanel {
    padding-left: 10px !important;
    padding-right: 10px;
    .fileSelect {
        padding: 1.5em 1em;
    }
}
.fileSelect {
    padding: 10px;
}
.fileSelect img {
    width: 100%;
}
.template_page {
    background: #383838;
}
.heading {
    padding: 10px 20px;
    display: flex;
    border-bottom: 1px solid #d6d6d6;
    letter-spacing: 1px;
    .label {
        padding: 1em 0;
        font-weight: 900;
    }
}

.inputs,
.events {
    padding: 0.9em 1em;
}

.events {
    display: flex;
    justify-content: flex-end;
}

.err {
    color: var(--color-danger);
    font-size: 0.8em;
}
.tabs {
    margin: 1em 0;
}
::v-deep .tabs .tab p {
    margin: 0.5em 0;
}
</style>
